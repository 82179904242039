@import "../../styles/colors.module";

.footer {
  height: 238px;

  .bottomBlockWrapper {
    display: flex;
  }

  .copyright {
    width: 1154px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 300;
    color: map_get($common, 'gray-4');
    margin-left: 60px;
    margin-top: 11px;
  }

  .logos {
    margin-left: 59px;
    margin-top: 21px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .logo18 {
    // position: absolute;
    background-image: url("../../assets/icons/18plus.png");
    width: 26px;
    height: 25px;
  }

  .logoGaming {
    // position: absolute;
    background-image: url("../../assets/icons/Gaming.png");
    width: 81px;
    height: 25px;
  }

  .logoJftf {
    // position: absolute;
    background-image: url("../../assets/icons/jftf 1.png");
    width: 35px;
    height: 35px;
  }

  .carousel {
    height: 28px;
    width: 85%;
    margin: 35px auto 40px;
    position: relative;

    .arrowButton {
      background-color: transparent;
      background-image: url("../../assets/icons/arrow2.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      position: absolute;
    }

    .arrowButtonLeft {
      left: -70px;
      top: -10px;
    }

    .arrowButtonRight {
      right: -70px;
      top: -10px;
      transform: rotate(180deg);
    }
  }

  .mobileLogos {
    display: none;
  }
}

:global(.tablet) .footer {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .copyright {
    width: 596px;
    font-size: 6px;
    line-height: 8px;
    font-weight: 300;
    margin-left: 40px;
    margin-top: 11px;
  }

  .logos {
    margin-left: 0px;
    margin-top: 11px;
    margin-right: 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }

  .mobileLogos {
    display: none;
  }

  .curacac {
    background-image: url("../../assets/icons/curacac.png");
    width: 93px;
    height: 31px;
  }

  .logo18 {
    // position: absolute;
    background-image: url("../../assets/icons/18plus.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }

  .logoGaming {
    // position: absolute;
    background-image: url("../../assets/icons/Gaming.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 41px;
    height: 12px;
  }

  .logoJftf {
    // position: absolute;
    background-image: url("../../assets/icons/jftf 1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
  }


  .carousel {
    margin: 20px auto 10px;

    .arrowButtonLeft {
      left: -40px;
      top: -10px;
    }

    .arrowButtonRight {
      right: -40px;
      top: -10px;
      transform: rotate(180deg);
    }
  }

  .playNow {
    margin-bottom: 37px;
  }

  .bottomBlockWrapper {
    margin-bottom: 25px;
  }
}

:global(.mobile) .footer {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .copyright {
    width: 100%;
    margin-bottom: 33px;
    margin-left: 0;
  }

  .logos {
    display: none;
  }

  .mobileLogos {
    display: flex;
    margin-top: 20px;
    margin-bottom: 24px;
    width: 250px;
    justify-content: space-around;

    .logo18,
    .logoGaming {
      position: static;
    }

    .curacac {
      background-image: url("../../assets/icons/curacac.png");
      width: 93px;
      height: 31px;
    }
  }

  .carousel {
    margin: 20px auto 10px;

    .arrowButtonLeft {
      left: -40px;
      top: -10px;
    }

    .arrowButtonRight {
      right: -40px;
      top: -10px;
      transform: rotate(180deg);
    }
  }

  .playNow {
    margin-bottom: 37px;
  }
}