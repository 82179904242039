*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul[class],
ol[class] {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}