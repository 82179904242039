@import "../../styles/colors.module";

.header {
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registerWrapper {
  margin-right: 25px;
  position: relative;
  -webkit-animation: 2.5s cubic-bezier(.455, .03, .515, .955) infinite both shake-horizontal, 4s linear infinite reminder;
  animation: 2.5s cubic-bezier(.455, .03, .515, .955) infinite both shake-horizontal, 4s linear infinite reminder;

  &:after {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    background: white;
    right: -4px;
    top: 10px;
    transform: rotate(45deg);
    background: linear-gradient(135deg, map_get($common, 'green-1') 0%, map_get($common, 'yellow-1') 100%);
    color: map_get($common, 'black-3');
  }

  .register {
    position: relative;
  }

}

:global(.mobile) .logo {
  width: 106px;
  height: 50px;
}

:global(.mobile) .header {
  height: 71px;
  padding-top: 19px;
}

:global(.tablet) .logo {
  width: 65px;
  height: 30px;
}

:global(.tablet) .header {
  height: 43px;
  padding-top: 9px;
}

:global(.tablet) .registerWrapper {
  margin-top: -3px;

  &:after {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background: white;
    right: -1px;
    top: 8px;
    transform: rotate(45deg);
    background: linear-gradient(135deg, map_get($common, 'green-1') 0%, map_get($common, 'yellow-1') 100%);
    color: map_get($common, 'black-3');
  }
}

.logo {
  max-width: 50%;
  max-height: 50%;
}

@-webkit-keyframes reminder {

  0%,
  30%,
  100% {
    opacity: 0
  }

  47%,
  92% {
    opacity: 1
  }
}

@keyframes reminder {

  0%,
  30%,
  100% {
    opacity: 0
  }

  47%,
  92% {
    opacity: 1
  }
}


@-webkit-keyframes shake-horizontal {

  0%,
  100% {
    left: 0
  }

  10%,
  30%,
  50%,
  70% {
    left: -5px
  }

  20%,
  40%,
  60% {
    left: 5px
  }

  80% {
    left: 4px
  }

  90% {
    left: -4px
  }
}


@keyframes shake-horizontal {

  0%,
  100% {
    left: 0
  }

  10%,
  30%,
  50%,
  70% {
    left: -5px
  }

  20%,
  40%,
  60% {
    left: 5px
  }

  80% {
    left: 4px
  }

  90% {
    left: -4px
  }
}