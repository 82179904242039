@import "../../styles/colors.module";

.hotGamesSection {
  height: 570px;
  background-image: url("../../assets/images/bottom-background.jpg");
  margin-bottom: 45px;
}

.hotGameTitle {
  font-size: 55px;
  line-height: 55px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 61px;
  text-indent: 50px;
}

.hotGameIcon {
  background-image: url("../../assets/icons/hotGame.gif");
  width: 91px;
  height: 91px;
  background-size: contain;
  position: absolute;
  top: 15px;
  left: 5px;
}

.hotGamesCards {
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  &:hover {
    .hotGameCard {
      width: 218px !important;
      height: 195px !important;
      // transform: rotate(0) !important;
      top: 0 !important;
      left: 0 !important;

      &.card1 {
        -webkit-animation: .6s linear infinite alternate bounce;
        animation: .6s linear infinite alternate bounce;
      }

      &.card2 {
        -webkit-animation: 1s linear infinite alternate bounce;
        animation: 1s linear infinite alternate bounce;
      }

      &.card3 {
        -webkit-animation: .8s linear infinite alternate bounce;
        animation: .8s linear infinite alternate bounce;
      }

      &.card4 {
        -webkit-animation: 1.1s linear infinite alternate bounce;
        animation: 1.1s linear infinite alternate bounce;
      }

      &.card5 {
        -webkit-animation: .5s linear infinite alternate bounce;
        animation: .5s linear infinite alternate bounce;
      }

      &.card6 {
        -webkit-animation: .9s linear infinite alternate bounce;
        animation: .9s linear infinite alternate bounce;
      }

      &.card7 {
        -webkit-animation: .6s linear infinite alternate bounce;
        animation: .6s linear infinite alternate bounce;
      }

      &.card8 {
        -webkit-animation: .7s linear infinite alternate bounce;
        animation: .7s linear infinite alternate bounce;
      }
    }

    .cardsTitle {
      opacity: 1;
    }
  }
}



.hotGameWrapper {
  position: absolute;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 10px;
}

.hotGamesRow {
  display: flex;
  gap: 23px;
  margin: 10px 0;
}

.cardsTitleWrapper {
  position: relative;
  z-index: 0;
  height: 86px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .cardsTitle {
    opacity: 0;
    white-space: pre-line;
    font-size: 34px;
    font-weight: 700;
    line-height: 39px;
    transition: all 0.4s ease-out;
    text-transform: uppercase;
    background: linear-gradient(180deg, map_get($common, 'red-1') 0%, map_get($common, 'yellow-1') 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-animation: 2s linear infinite choose;
    animation: 2s linear infinite choose;
  }
}

.hotGameCard {
  position: relative;
  border: 2px solid;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  transition: all 0.4s ease-out;
  cursor: pointer;

  &.card1 {
    -webkit-animation: .6s linear infinite alternate bounceWithRotation1;
    animation: .6s linear infinite alternate bounceWithRotation1;
  }

  &.card3 {
    -webkit-animation: .8s linear infinite alternate bounceWithRotation2;
    animation: .8s linear infinite alternate bounceWithRotation2;
  }

  &.card4 {
    -webkit-animation: 1.1s linear infinite alternate bounceWithRotation3;
    animation: 1.1s linear infinite alternate bounceWithRotation3;
  }

  &.card6 {
    -webkit-animation: .9s linear infinite alternate bounceWithRotation4;
    animation: .9s linear infinite alternate bounceWithRotation4;
  }

  &:hover {
    .hotGameCardBackground {
      opacity: 1;
    }
  }

  .hotGameCardBackground {
    width: 100%;
    height: 100%;
    background: rgba(map_get($common, 'black-1'), 0.5);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.4s ease-out;
  }

  .hotGameCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hotGameCardText {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
  }
}

.swiperWrapper {
  width: 100%;
  height: 450px;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.swiper {
  width: 748px;
  left: calc((100% - 748px) / 2);
  top: 215px;
  position: absolute;
  display: flex;

  .swiperSlide {
    background-size: cover;
    border: 1px solid;
    width: 236px;
    height: 201px;
    border-radius: 8px;

    &.swiperSlide1 {
      border-color: map_get($common, "blue-1");
    }

    &.swiperSlide2 {
      border-color: map_get($common, "red-4");
    }

    &.swiperSlide3 {
      border-color: map_get($common, "blue-2");
    }

    &.swiperSlide4 {
      border-color: map_get($common, "gray-5");
    }

    &.swiperSlide5 {
      border-color: map_get($common, "blue-1");
    }

    &.swiperSlide6 {
      border-color: map_get($common, "red-4");
    }

    &.swiperSlide7 {
      border-color: map_get($common, "blue-2");
    }

    &.swiperSlide8 {
      border-color: map_get($common, "gray-5");
    }
  }
}

.iconArrows {
  background-image: url("../../assets/icons/arrows.png");
  width: 19px;
  height: 8px;
  position: absolute;
  bottom: -125px;
  right: 20px;
}

.iconHand {
  background-image: url("../../assets/icons/hand.png");
  width: 30px;
  height: 33px;
  position: absolute;
  bottom: -165px;
  right: 8px;
  -webkit-animation: 2s linear infinite both handAnimation;
  animation: 2s linear infinite both handAnimation;
}

:global(.slider) {
  height: 6px;
  width: 100%;
  background: map_get($common, "gray-7");
  border-radius: 8px;
  margin-top: 100px;
  position: relative;

  :global(.thumb-0) {
    height: 8px;
    width: 99px;
    background: map_get($common, "green-2");
    border-radius: 8px;
    position: absolute;
    top: -1px;
    left: 0;
    outline: none;
  }
}

:global(.mobile) {
  .hotGamesSection {
    background-image: url("../../assets/images/bottom-background.jpg");
    background-position: center;
  }

  .hotGameTitle {
    font-size: 33px;
    line-height: 34px;
    margin-bottom: 255px;
    text-indent: 38px;
  }

  .hotGameIcon {
    width: 65px;
    height: 65px;
    top: 10px;
    left: 5px;
  }

  .hotGameButtonWrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -70px;
    display: flex;
    justify-content: center;
  }
}

:global(.tablet) {
  .hotGamesSection {
    height: 294px;
    background-image: url("../../assets/images/bottom-background.jpg");
    margin-bottom: 45px;
  }

  .hotGamesCards {
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    &:hover {
      .hotGameCard {
        width: 99px !important;
        height: 99px !important;
        // transform: rotate(0) !important;
        top: 0 !important;
        left: 0 !important;
      }
    }
  }

  .hotGameCardText {
    margin-bottom: 16px;
    max-width: 75%;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .cardsTitleWrapper {
    height: 70px;

    .cardsTitle {
      font-size: 30px;
      line-height: 34px;
    }
  }

  .hotGamesSection {
    background-image: url("../../assets/images/bottom-background.jpg");
    background-position: center;
  }

  .hotGameTitle {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 0px;
    text-indent: 27px;
  }

  .hotGameIcon {
    width: 47px;
    height: 47px;
    top: 5px;
    left: 5px;
  }

  .hotGameButtonWrapper {
    position: absolute;
    left: 16px;
    // transform: translateX(-50%);
    bottom: 98px;
    display: flex;
    justify-content: center;
  }

  .card1 {
    -webkit-animation: .6s linear infinite alternate bounceWithRotation5;
    animation: .6s linear infinite alternate bounceWithRotation5;
  }

  .card3 {
    -webkit-animation: .8s linear infinite alternate bounceWithRotation6;
    animation: .8s linear infinite alternate bounceWithRotation6;
  }

  .card4 {
    -webkit-animation: 1.1s linear infinite alternate bounceWithRotation7;
    animation: 1.1s linear infinite alternate bounceWithRotation7;
  }

  .card6 {
    -webkit-animation: .9s linear infinite alternate bounceWithRotation8;
    animation: .9s linear infinite alternate bounceWithRotation8;
  }

}

@-webkit-keyframes choose {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes choose {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@-webkit-keyframes handAnimation {

  0%,
  100% {
    right: 8px;
  }

  50% {
    right: -3px;
  }
}

@keyframes handAnimation {

  0%,
  100% {
    right: 8px;
  }

  50% {
    right: -3px;
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: rotate(0) translateY(0);
  }

  50% {
    transform: rotate(0) translateY(5px) !important;
  }

  100% {
    transform: rotate(0) translateY(-5px);
  }
}

@keyframes bounce {
  0% {
    transform: rotate(0) translateY(0);
  }

  50% {
    transform: rotate(0) translateY(5px) !important;
  }

  100% {
    transform: rotate(0) translateY(-5px);
  }
}


@-webkit-keyframes bounceWithRotation1 {
  0% {
    top: 120px
  }

  50% {
    top: 115px
  }

  100% {
    top: 125px
  }
}


@keyframes bounceWithRotation1 {
  0% {
    top: 120px
  }

  50% {
    top: 115px
  }

  100% {
    top: 125px
  }
}

@-webkit-keyframes bounceWithRotation2 {
  0% {
    top: 62px
  }

  50% {
    top: 67px
  }

  100% {
    top: 58px
  }
}

@keyframes bounceWithRotation2 {
  0% {
    top: 62px
  }

  50% {
    top: 67px
  }

  100% {
    top: 58px
  }
}

@-webkit-keyframes bounceWithRotation3 {
  0% {
    top: 120px
  }

  50% {
    top: 115px
  }

  100% {
    top: 125px
  }
}

@keyframes bounceWithRotation3 {
  0% {
    top: 120px
  }

  50% {
    top: 115px
  }

  100% {
    top: 125px
  }
}

@-webkit-keyframes bounceWithRotation4 {
  0% {
    top: -475px
  }

  50% {
    top: -480px
  }

  100% {
    top: -470px
  }
}

@keyframes bounceWithRotation4 {
  0% {
    top: -475px
  }

  50% {
    top: -480px
  }

  100% {
    top: -470px
  }
}

@-webkit-keyframes bounceWithRotation5 {
  0% {
    top: 62px
  }

  50% {
    top: 67px
  }

  100% {
    top: 62px
  }
}

@keyframes bounceWithRotation5 {
  0% {
    top: 62px
  }

  50% {
    top: 67px
  }

  100% {
    top: 62px
  }
}

@-webkit-keyframes bounceWithRotation6 {
  0% {
    top: 50px
  }

  50% {
    top: 55px
  }

  100% {
    top: 50px
  }
}

@keyframes bounceWithRotation6 {
  0% {
    top: 50px
  }

  50% {
    top: 55px
  }

  100% {
    top: 50px
  }
}

@-webkit-keyframes bounceWithRotation7 {
  0% {
    top: 92px
  }

  50% {
    top: 97px
  }

  100% {
    top: 92px
  }
}

@keyframes bounceWithRotation7 {
  0% {
    top: 92px
  }

  50% {
    top: 97px
  }

  100% {
    top: 92px
  }
}

@-webkit-keyframes bounceWithRotation8 {
  0% {
    top: -261px
  }

  50% {
    top: -254px
  }

  100% {
    top: -261px
  }
}

@keyframes bounceWithRotation8 {
  0% {
    top: -261px
  }

  50% {
    top: -254px
  }

  100% {
    top: -261px
  }
}