@import "../../styles/colors.module";

.gameCard {
  height: 606px;
  width: 456px;
  margin-top: 44px;
  border-radius: 8px;
  position: relative;

  .front,
  .back {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
    border-radius: 8px;
  }

  .back {
    display: none;
  }

  &.flipInStart {
    -webkit-animation: .3s linear card-flip-start;
    animation: .3s linear card-flip-start;
  }

  &.flipInEnd {
    -webkit-animation: .3s linear card-flip-end;
    animation: .3s linear card-flip-end;
  }

  &.flipOutStart {
    -webkit-animation: .3s linear card-flip-out-start;
    animation: .3s linear card-flip-out-start;
  }

  &.flipOutEnd {
    -webkit-animation: .3s linear card-flip-out-end;
    animation: .3s linear card-flip-out-end;
  }

  &:hover,
  &:global(.element-show) {
    .labelText {
      min-width: 100%;
    }

    .cardImage {
      transform: scale(1.18) translate(-42%);
    }

    .background {
      background-position: 0 30px;
    }

    &.gameCardGradient {
      .background {
        &:before {
          top: 30px;
          height: 318px;
        }
      }
    }
  }

  .content {
    height: 426px;
    background: linear-gradient(180deg, map_get($common, 'violet-2') 8.09%, map_get($common, 'violet-1') 108.78%);
    border-radius: 0 0 8px 8px;
    padding: 204px 11px 0 31px;
    position: relative;
  }

  .title {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 110%;
  }

  .buttonGroup {
    position: absolute;
    bottom: 43px;
  }

  .playNow {
    margin-right: 25px;
  }

  .background {
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 372px;
    z-index: 2;
    transition: all 0.3s ease-out;
    border-radius: 8px;
  }

  .labelWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

  }

  .label {
    position: absolute;
    left: - 5%;
    top: 242px;
    padding: 0 20px 0 40px;
    height: 50px;
    transform: rotate(-13deg);
    width: 110%;
    background: map_get($common, 'green-2');
    display: flex;
    align-items: center;
  }

  .labelText {
    font-size: 24px;
    line-height: 30px;
    color: map_get($common, 'black-1');
    text-transform: uppercase;
    transition: all 0.3s ease-out;
    min-width: 20px;
    text-align: right;
  }

  .cardImage {
    position: absolute;
    top: -17px;
    left: 50%;
    transform: translate(-50%);
    width: 324px;
    height: 378px;
    background-repeat: no-repeat;
    transition: all 0.3s ease-out;
  }


  &.gameCardGradient {
    .background {
      height: 100%;
      border-radius: 8px;

      &:before {
        content: '';
        position: absolute;
        border-radius: 8px;
        top: 0;
        width: 100%;
        height: 332px;
        transition: all 0.3s ease-out;
        background: linear-gradient(180deg, map_get($common, 'violet-1') 0%, transparent 100%);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 175px;
        background: linear-gradient(0deg, map_get($common, 'black-2') 0%, transparent 100%);
      }
    }

    .cardImage {
      z-index: 10;
      width: 372px;
      height: 299px;
      transform: translate(-52%);
      top: -24px;
    }

    &:hover,
    &:global(.element-show) {
      .cardImage {
        transform: scale(1.2) translate(-43%, 10%) !important;
        top: -75px;
      }
    }

    .content {
      background: none;
      z-index: 10;
    }
  }

  &.gameCardLearnMore {
    height: 650px;
    margin-top: 0;
    background: linear-gradient(180deg, map_get($common, 'violet-2') 37.73%, map_get($common, 'violet-1') 105.95%);
    padding: 48px 25px 36px;
    white-space: pre-wrap;

    .back {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .front {
      display: none;
    }

    .learnMoreTitle {
      font-size: 30px;
      line-height: 37px;
      color: map_get($common, 'white-2');
      margin-bottom: 0;
      text-transform: uppercase;
    }

    .learnMoreText {
      font-size: 27px;
      font-weight: 300;
      line-height: 33px;
      // text-transform: lowercase;
      max-height: 463px;
      overflow-y: scroll;
      margin-bottom: 25px;
    }

    .closeButton {
      position: absolute;
      right: 23px;
      top: 16px;
      width: 26px;
      height: 27px;
      background: url("../../assets/icons/close.png");
    }
  }
}

:global(.mobile) .gameCard {
  width: 100%;
  border-radius: 8px;
  height: 436px;
  margin-top: 32px;

  &:hover,
  &:global(.element-show) {
    .cardImage {
      transform: scale(1.2) translate(-43%, 10%) !important;
      top: -30px;
    }
  }

  .content {
    height: 307px;
    border-radius: 0 0 8px 8px;
    padding: 161px 11px 0 22px;
  }

  .title {
    font-size: 23px;
    line-height: 110%;
  }

  .buttonGroup {
    bottom: 25px;
  }

  .playNow {
    margin-right: 15px;
  }

  .background {
    top: 0;
    height: 300px;
    width: 100%;
    background-size: 100% 100%;
  }

  .label {
    left: -5%;
    top: 202px;
    padding: 0 20px 0 25px;
    height: 42px;
  }

  .labelText {
    font-size: 17px;
    line-height: 21px;
  }

  .cardImage {
    top: -2px;
    width: 233px;
    height: 233px;
    background-size: cover;
  }

  &.gameCardGradient {
    .background {
      height: 100%;
      border-radius: 8px;
      background-size: 100%;
      background-position-y: 40px;

      &:before {
        border-radius: 8px;
        height: 220px;
      }

      &:after {
        height: 125px;
      }
    }

    .cardImage {
      width: 273px;
      height: 219px;
      transform: translate(-54%) scale(1.1);
    }
  }

  &.gameCardLearnMore {
    height: 468px;
    padding: 34px 17px 26px;
    margin-top: 0;

    .learnMoreTitle {
      font-size: 20px;
      line-height: 24px;
    }

    .learnMoreText {
      font-size: 20px;
      line-height: 25px;
      max-height: 316px;
    }

    .closeButton {
      position: absolute;
      right: 18px;
      top: 11px;
      width: 20px;
      height: 21px;
      background-size: contain;
      background-image: url("../../assets/icons/close.png");
    }
  }
}

:global(.tablet) .gameCard {
  width: 235px;
  border-radius: 8px;
  height: 335px;
  margin-top: 32px;

  &:global(.element-show) {
    .cardImage {
      transform: scale(1.2) translate(-43%, 10%) !important;
      top: 15px;
    }

    .labelText {
      min-width: auto;
    }
  }

  &:hover(.element-show) {
    .labelText {
      min-width: auto;
    }
  }

  .content {
    height: 207px;
    border-radius: 0 0 8px 8px;
    padding: 10px 11px 0 22px;
  }

  .title {
    width: 195px;
    font-size: 16px;
    line-height: 17.6px;
    margin-top: 90px;
  }

  .buttonGroup {
    bottom: 25px;
  }

  .playNow {
    margin-right: 5px;
  }

  .background {
    top: 0;
    height: 215px;
    width: 100%;
    background-size: cover;
  }

  .labelWrapper {
    width: 100%;
    height: 222px;
    overflow: hidden;
    position: relative;

  }

  .label {
    left: -5%;
    top: 163px;
    padding: 0 20px 0 25px;
    height: 30px;
  }

  .labelText {
    font-size: 12px;
    line-height: 14px;
  }

  .cardImage {
    top: 40px;
    width: 148px;
    height: 195px;
    background-size: contain;
  }

  &.gameCardGradient {
    .background {
      height: 100%;
      border-radius: 8px;
      background-size: 100%;
      background-position-y: 40px;

      &:before {
        border-radius: 8px;
        height: 220px;
      }

      &:after {
        height: 125px;
      }
    }

    .cardImage {
      width: 169px;
      height: 165px;
      transform: translate(-54%) scale(1.1);
      background-size: contain;
    }
  }

  &.gameCardLearnMore {
    height: 306px;
    padding: 34px 17px 26px;
    margin-top: 61px;
    width: 235px;

    .learnMoreTitle {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    .learnMoreText {
      font-size: 16px;
      line-height: 18px;
      height: 60%;
      overflow-y: scroll;
    }

    .closeButton {
      position: absolute;
      right: 12px;
      top: 11px;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-image: url("../../assets/icons/close.png");
      background-repeat: no-repeat;
    }
  }
}

@-webkit-keyframes card-flip-start {
  0% {
    transform: perspective(700px) rotateY(0deg);
  }

  100% {
    transform: perspective(700px) rotateY(90deg);
  }
}

@keyframes card-flip-start {
  0% {
    transform: perspective(700px) rotateY(0deg);
  }

  100% {
    transform: perspective(700px) rotateY(90deg);
  }
}

@-webkit-keyframes card-flip-end {
  0% {
    transform: perspective(700px) rotateY(-90deg);
  }

  100% {
    transform: perspective(700px) rotateY(0deg);
  }
}

@keyframes card-flip-end {
  0% {
    transform: perspective(700px) rotateY(-90deg);
  }

  100% {
    transform: perspective(700px) rotateY(0deg);
  }
}

@-webkit-keyframes card-flip-out-end {
  0% {
    transform: perspective(700px) rotateY(90deg);
  }

  100% {
    transform: perspective(700px) rotateY(0deg);
  }
}

@keyframes card-flip-out-end {
  0% {
    transform: perspective(700px) rotateY(90deg);
  }

  100% {
    transform: perspective(700px) rotateY(0deg);
  }
}

@-webkit-keyframes card-flip-out-start {
  0% {
    transform: perspective(700px) rotateY(0deg);
  }

  100% {
    transform: perspective(700px) rotateY(-90deg);
  }
}

@keyframes card-flip-out-start {
  0% {
    transform: perspective(700px) rotateY(0deg);
  }

  100% {
    transform: perspective(700px) rotateY(-90deg);
  }
}