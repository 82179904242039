@import "../../styles/colors.module";

.gameCardSection {
  display: flex;
  gap: 24px;
  margin-bottom: 41px !important;
  position: relative;
  z-index: 15;
}

.timer {
  padding: 16px 12px !important;
  margin-bottom: -60px;
}

.timerTitle {
  font-size: 28px;
  line-height: 35px;
  color: map_get($common, 'green-2');
  margin-bottom: 8px;
}

:global(.mobile) .gameCardSection {
  flex-direction: column;
  gap: 9px;
}

:global(.tablet) .gameCardSection {
  margin-top: 20px;
}